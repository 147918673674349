import { addDoc, collection, query, where } from 'firebase/firestore'
import { FireBaseStore } from '@/model/firebase/google-fire-base'
import { getCurrentStoreId, getCurrentUserId } from '@/model/SaaS/user'
import { resultOf } from '@/model/firebase/queryUtils'
import hillo from 'hillo'
import { cloudUrl } from '@/model/SaaS/subscriptionService'
import i18n from '@/i18n'

const guideStatus = 'guideStatus'

export async function setUserGuideStaus (guideItem) {
  const userId = getCurrentUserId()
  return await addDoc(collection(FireBaseStore, guideStatus), {
    userId,
    guideItem,
  })
}

export async function getAllGuideStatusForUser () {
  const res = query(collection(FireBaseStore, guideStatus),
    where('userId', '==', getCurrentUserId()))
  return (await resultOf(res))
}

export const missionList = [
  { value: 1, title: i18n.t('GuideQuest00'), status: 0 },
  { value: 2, title: i18n.t('GuideQuest01'), status: 0 },
  { value: 3, title: i18n.t('GuideQuest02'), status: 0 },
  { value: 4, title: i18n.t('GuideQuest03'), status: 0 },
  { value: 5, title: i18n.t('GuideQuest04'), status: 0 },
  { value: 6, title: i18n.t('GuideQuest05'), status: 0 },
]

export async function endTrialMode () {
  const currentStoreId = await getCurrentStoreId()
  return (await hillo.jsonPost(cloudUrl + '/virtualDevice/disableTestMode', {
    deviceId: currentStoreId,
  }))
}
